<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>
        <v-container xl fluid> 
            <v-row>
                <div style="display: flex; width: 100%; justify-content: flex-start;">
                    <hr class="vr" />
                    <p class="tblName pb-2" >Reporte de timbres</p>
                </div>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card style="border-radius: 20px;" elevation="0">
                        <ValidationObserver ref="form" v-slot="{invalid}">
                            <v-card-text class="px-30px pt-30px pb-0">
                                <v-row class="my-0">
                                    <v-col v-if="rol=='root'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0 my-0">
                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Cliente"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                required
                                                :error-messages="errors"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" :sm="rol=='root'?6:12" :md="rol=='root'?6:12" :lg="rol=='root'?6:12" :xl="rol=='root'?6:12" class="py-0 my-0">
                                        <VuetifyMultipleAutocomplete 
                                            outlined
                                            :items="registrosPatronales"
                                            item-text="nombre"
                                            item-value="id"
                                            @dataMultiple="(data)=>registrosPatronalesSeleccionados = data"
                                            label="Registros patronales"
                                            ref="multRegistroPatronal"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <VuetifyDataPicker 
                                            rules="required"
                                            outlined
                                            :valorDefecto="fecha_inicio"
                                            v-model="fecha_inicio"
                                            label="Fecha inicio"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaInicio"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <VuetifyDataPicker 
                                            rules="required"
                                            outlined
                                            :valorDefecto="fecha_fin"
                                            v-model="fecha_fin"
                                            label="Fecha fin"
                                            placeholder="Fecha fin"
                                            ref="FechaFin"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end pa-30px">
                                <v-btn
                                    class="btnGuardar"
                                    @click="consultar()"
                                    :loading="isLoadingConsultar"
                                    :disabled="invalid" 
                                >
                                    Consultar
                                </v-btn>
                                <v-btn
                                    class="btnGuardar"
                                    @click="descargar()"
                                    :loading="isLoadingConsultar"
                                    :disabled="invalid" 
                                >
                                    Descargar
                                </v-btn>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-0 pl-2 outlined_v_icon">info</v-icon>
                                    </template>
                                    <span class="pb-7 textTooltip">
                                        Para un detalle mas general descargar el reporte.
                                    </span>
                                </v-tooltip>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <simple-table
                    class="tablaWidth"
                    :rows="datosTimbres"
                    :columns="headersTable"
                    :perPage="[10, 25, 50, 100]"
                    shortPagination
                    :emptyTable="'emptyTableWhite'"
                    :classes="tableClasses"
                >
                    <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                        <tr :key="item.id" v-for="(item, index) in data">
                            <td style="background-color: #ffffff !important;"><div>{{item.nombre_registro_patronal}}</div></td>
                            <td style="background-color: #ffffff !important;"><div>{{item.nombre_completo}}</div></td>
                            <td style="background-color: #ffffff !important;"><div>{{item.numero}}</div></td>
                            <td style="background-color: #ffffff !important;"><div>{{item.fecha_format}}</div></td>
                        </tr>
                    </tbody>
                </simple-table>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Vue from 'vue';
//APIS
import apiClientes from "@/api/clientes";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiTimbres from "@/api/nominas/configurarTimbres";

//COMPONENTS
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import VuetifyMultipleAutocomplete from "@/components/VuetifyMultipleAutocomplete.vue";
import VuetifyDataPicker from "@/components/VuetifyDatePicker.vue"

//PLUGINS
import Notify from "@/plugins/notify";

    export default {
        components: {
            VuetifyMultipleAutocomplete,
            VuetifyDataPicker,
            DatatableSimple,
            "simple-table": DatatableSimple,
        },
        data() {
            return {
                datosLogin                          : null,
                rol                                 : null,
                clientes                            : [],
                cliente_value                       : null,
                registrosPatronales                 : [],
                registrosPatronalesSeleccionados    : [],
                fecha_inicio                        : null,
                fecha_fin                           : null,
                isLoadingConsultar                  : false,
                datosTimbres                        : [],
                headersTable                        : [
                    {
                        label: 'Registro patronal',
                        name: 'nombre',
                        filterable: false
                    },
                    {
                        label: 'Empleado',
                        name: 'empleadp',
                        filterable: false
                    },
                    {
                        label: 'No. Periodo',
                        name: 'periodo',
                        filterable: false
                    },
                    {
                        label: 'Fecha emisión',
                        name: 'fecha',
                        filterable: false
                    }
                ],
                tableClasses: {
                    table: {
                        "no-box-shadow":true
                    },
                },
                fraseLoading:'',
                loadingLottie: false,
                
            }
        },
        watch:{
            cliente_value: function(val){
                this.getRegistrosPatronales(val);
            }
        },  
        methods: {
            /**
             * @method getClientes Este método realiza la petición a la API de clientes.
             * @description Este método se ejecuta al seleccionar al iniciar la vista. El resultado se almacena en el array clientes=[]
            */
            async getClientes() {
                let parametros = { activo: true, paginate: false };
                await apiClientes.getClientes(parametros).then(response => {
                    this.clientes = response.data;
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los clientes");
                });
            },

            /**
             * @method getRegistrosPatronales Este método realiza la petición a la API de Registros Patronales.
             * @description Este método se ejecuta al seleccionar un cliente. El resultado se almacena en el array registrosPatronales=[]
             */
            async getRegistrosPatronales(cliente){
                if(cliente == null){
                    this.registrosPatronales                = [];
                    this.registrosPatronalesSeleccionados   = [];
                    return;
                }

                let parametros = {
                    activo: true,
                    paginate: false,
                    cliente_id: cliente,
                };

                await apiRegistroPatronal.find(parametros).then(response => {
                    this.registrosPatronales = response.data;
                })
                .catch(err => {
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
                })
            },
            async consultar(){
                let parametros = {
                    cliente_id : this.cliente_value,
                    registros_patronales: this.registrosPatronalesSeleccionados,
                    fecha_inicio : this.fecha_inicio,
                    fecha_fin: this.fecha_fin,
                    tipo:"json"
                }
                this.isLoadingConsultar = true;

                await apiTimbres.reporteTimbres(parametros).then(response => {
                    this.datosTimbres = response.data;
                    this.isLoadingConsultar = false;
                })
                .catch(err => {
                    this.isLoadingConsultar = false;
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al realizar la consulta");
                })
            },
            descargar(){
                this.loadingLottie = true;
                this.fraseLoading = 'Descargando';
                const FileDownload = require("js-file-download");
                let nombre;
                let tipo = 'xlsx'
                nombre = "Reporte de timbres."+tipo;
                let url = "reportes/reporte-timbres";

                let parametros = {
                    cliente_id : this.cliente_value,
                    registros_patronales: this.registrosPatronalesSeleccionados,
                    fecha_inicio : this.fecha_inicio,
                    fecha_fin: this.fecha_fin,
                    tipo:tipo
                }

                try {
                    Vue.axios({
                        method: "POST",
                        responseType: "blob",
                        url: url,
                        data: parametros,
                    }).then(async (response, status, xhr) => {
                        FileDownload(response.data, nombre);
                        this.loadingLottie = false;
                    })
                    .catch(async (error)=>{
                        //console.log(err);
                        this.loadingLottie = false;
                        let errorString = error.response.data;
                        if (
                            error.request.responseType === 'blob' &&
                            error.response.data instanceof Blob &&
                            error.response.data.type &&
                            error.response.data.type.toLowerCase().indexOf('json') != -1
                        ){
                            errorString = JSON.parse(await error.response.data.text());
                            console.log(errorString)

                            if(typeof errorString.error === 'string'){
                                Notify.ErrorToast(errorString.error);
                            }else{
                                let error = Object.values(errorString.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                Notify.ErrorToast(leyenda);
                            }
                        }; 
                    });
                } 
                catch (err) {
                    Notify.ErrorToast("No se pudo realizar la petición");
                    console.log(err);
                    this.loadingLottie = false;
                }
            },
        },
        created() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            if(this.rol == "root"){
                this.getClientes();
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
            }
        },
    }
</script>
<style scoped>
    
</style>